import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';



import FigmaCourse from '../pics/Banner_Figma_course.jpg'
import Instalments from '../components/instalments.js'

import Contacts from '../components/contactform';

const FigmaCrashCourse = () => {

    let [courses, setCourses] = useState({
        durationdays: 5,
        durationweeks: 1,
        fulldesc1: "1-week course, in-person, in English, part-time, Monday–Friday, 9:00 to 13:00",
        fulldesc2: "Tuition payment 420€",
        fullprice: 420,
        image: "https://barcelonacodeschool.com/files/Banner_Figma_course.jpg",
        priceoptions: [],
        shortdesc: "Learning Figma in 5 days",
        signupprice: 420,
        startdates: ['December 9 - 13, 2024'],
        starttime: "9:00",
        tag: "figmaCrash",
        title: "Design and Prototype in Figma course in Barcelona Code School",
    })

    // useEffect(()=>{
    // 	let getData = async () => {
    // 		let data = await axios.get('https://barcelonacodeschool.com/events/reactCrash')
    // 		setCourses(data.data)
    // 		console.log(data.data)
    // 	}
    // 	getData()
    // },[])

    let renderCourses = () => {
        return courses.startdates.map((date, i) => (
            <div key={i} className="whiteCard bottomSpace flex300" >
                <span>
                    <h2>{date}</h2>
                    <h4>{courses.title}</h4>
                    <p>{courses.shortdesc}</p>
                    {date.includes("January 29, 2024") && <h2 className='highlighter'>Group is full!</h2>}
                </span>
                <span>
                    <p>{courses.fulldesc1}</p>
                    <p>{courses.fulldesc2}</p>
                    {!date.includes("January 29, 2024") && <Link to="/register" state={{ course: courses.title, date: date, cost: courses.signupprice, images: [courses.image], cryptoID: '484d04f6-c7b4-437b-a73b-05530babc556' }}>
                        <button style={{ 'marginBottom': '1em' }}>Sign up</button>
                    </Link>}
                    <p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
                </span>
            </div>
        ))
    }

    return <div className="flexContent"
    >
        <Helmet

            title="Design and Prototype in Figma course in Barcelona Code School"
            meta={[
                { name: 'description', content: 'Learn Figma in just 5 days with our hands-on UX/UI design course! Perfect for beginners and professionals. Master wireframes, prototypes, design systems, and more. Enroll today to start your design journey!' },
                { name: 'keywords', content: 'learn figma, figma course, design in figma' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { property: "og:title", content: "Design and Prototype in Figma course in Barcelona Code School" },
                { property: "og:description", content: "Learn Figma in just 5 days with our hands-on UX/UI design course! Perfect for beginners and professionals. Master wireframes, prototypes, design systems, and more. Enroll today to start your design journey!" },
                { property: "og:image", content: "https://barcelonacodeschool.com/files/Banner_Figma_course.jpg" },
                { property: "og:url", content: "https://barcelonacodeschool.com/design-and-prototype-in-figma" },
                { name: "twitter:card", content: "summary_large_image" }
            ]}
            script={[
                { "src": "https://js.stripe.com/v3/" }
            ]}
        />
        <h1 className='transparentContainer flex1200'>Figma Crash Course in Barcelona Code School</h1>

        <img
            className='banners flex1200 offsetImage'
            src={FigmaCourse}
            alt='Figma Crash Course in Barcelona Code School' />


        <h2 className='transparentContainer flex450'>Learn Figma from zero to hero in a practical way</h2>




        <div className='socialsharebuttons transparentContainer'>
            <div className='socialshare'>
                <a className="twitter-share-button "
                    href="https://twitter.com/intent/tweet?text='Figma Intensive Course in Barcelona Code School'&url=https://barcelonacodeschool.com/design-and-prototype-in-figma/"
                    data-size="large">
                    <button>tweet</button>
                </a>
            </div>

            <div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/design-and-prototype-in-figma/" className="fb-xfbml-parse-ignore">
                    <button>facebook</button>
                </a>
            </div>
        </div>


        <div className='transparentContainer'>
            <article className='whiteCard flex300'>
                <ul className='neonList'>
                    <li><span className='highlighter'>Intensive in-person</span> hands-on training</li>
                    <li><span className='highlighter'>1 week</span></li>
                    <li><span className='highlighter'>Daily classes</span> 9:00 to 13:00</li>
                    <li>The course is taught in <span className='highlighter'>English</span></li>
                    <li>From zero to 👸 and 🤴</li>
                    <li>Learn by doing</li>
                </ul>
            </article>
        </div>



        <article className="transparentContainer flex450">
            <h2>What you'll learn</h2>
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>Benefits of Figma</li>
                    <li>Using shapes, frames, text</li>
                    <li>Real-time collaboration features</li>
                    <li>Structuring design files</li>
                    <li>Low, mid and high fidelity wireframing</li>
                    <li>Frames, grids and layouts</li>
                    <li>Components and reusability</li>
                    <li>Static and interactive prototypes</li>
                    <li>Transitions and animations</li>
                    <li>Auto layout</li>
                    <li>Design systems</li>
                    <li>Accessibility in UX and UI</li>
                    <li>Exporting and handoff</li>
                </ul>
            </div>
        </article>



        <article className="transparentContainer flex300">
            <h2>Prerequisites</h2>
            <div className='whiteCard'>
                <p>This course is aimed at aspiring UX and UI designers who are willing to learn or improve their Figma skills.</p>
            </div>
        </article>



        <article className='transparentContainer flex225'>
            <h2>The outcomes</h2>
            <div className='whiteCard'>
            <ul className='neonList'>
                    <li><strong>Certificate of completion</strong></li>
                    <li><strong>Web app design</strong></li>
                    <li><strong>Mobile app design</strong></li>
                    <li>Demonstrable <strong>confidence with using Figma</strong> with course projects added to your portfolio</li>
                </ul>
            </div>
        </article>

		<article className='transparentContainer flex675 '>
			<h2>Instructor</h2>
			<div className='whiteCard'>
				<p><strong>Chris Kelly</strong>, our current Head UX Instructor has been teaching UX/UI Design at Barcelona Code School since 2022.</p>
                <p>Previousely Chris has been working as a UX and UI designer as a freelancer delivering projects across different industries. His ability to understand client's needs and come up with the best outcomes is leading students through their projects and making sure that everybody learns UX in the most practical hands-on approach aligned with user centric workflow.</p>
                <p>Through a variety of influences, Chris combines his understanding of visual composition and narrative to inform his work believing that the individual and the subconscious psychological drivers that influence us, should be at the heart of every ethical design decision make. </p>
			</div>
            </article>


        <article className='transparentContainer flex225'>
            <h2>Tuition</h2>
            <div className='whiteCard'>
                <h2>420€</h2>
                <p>100% is paid upon registration.</p>
                <Instalments />
            </div>
        </article>


        <article className="transparentContainer flex1200 flexContent">
            <h2>Access</h2>
            <div className='flexContent'>
                {renderCourses()}
            </div>
        </article>

        <article className='flex450'>
            <Contacts title={'Inquiry about the course'} subject={'Inquiry about Figma Crash Course'} />
        </article>
    </div>

}

export default FigmaCrashCourse
